import React from "react"
import Styled from "styled-components"
import Moment from "moment-timezone"

import AppContext from "../contexts/app"
import AnitaAPI from "../lib/AnitaAPI"

const HOST = process.env.NODE_ENV === "development"
  ? "http://192.168.0.90:5643"
  : "https://api.anita-delivery.com"

export default class Orders extends React.Component {
  static contextType = AppContext

  constructor(props) {
    super(props)

    this.state = {
      premises: [],
      selectedPremisesID: undefined,
      page: 1,
      orders: []
    }

    this.rootRef = React.createRef()
  }

  async componentDidMount() {
    const premises = await AnitaAPI.Merchant.getPremises()

    this.setState({
      selectedPremisesID: premises.length === 1
        ? premises[0].id
        : undefined,
      premises
    }, this.fetchOrders)
  }

  render() {
    const { premises, selectedPremisesID, page, orders } = this.state

    return (
      <Root ref={this.rootRef}>
        <h1>Order history</h1>
        {premises && premises.length > 1 &&
          <PremisesPicker
           value={this.state.selectedPremisesID}
           onChange={(event) => this.setState({ selectedPremisesID: event.target.value }, this.fetchOrders)}
          >
            <option>Select premises...</option>
            {premises.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
          </PremisesPicker>
        }
        {selectedPremisesID &&
          <>
            <ControlsContainer>
              <Button
                disabled={page === 1}
                onClick={() => this.setState({ page: Math.max(page - 1, 1) }, this.fetchOrders)}
              >
                Previous
              </Button>
              <p>Page {page}</p>
              <Button
                disabled={!orders || orders.length !== 50}
                onClick={() => this.setState({ page: page + 1 }, this.fetchOrders)}
              >
                Next
              </Button>
            </ControlsContainer>
            <OrdersTable>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>ID</th>
                  <th>Total</th>
                  <th>Receipt</th>
                </tr>
              </thead>
              <tbody>
                {orders && orders.map(order =>
                  <tr key={order.id}>
                    <td>{Moment(order.createdAt).tz("Europe/London").format("D/M/YY h:mm A")}</td>
                    <td>{order.publicOrderNumber ? order.publicOrderNumber : order.id}</td>
                    <td>£{(order.subtotal / 100).toFixed(2)}</td>
                    <td>
                      <a
                        href={`${HOST}/orders/receipts/${order.receiptID}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View receipt
                      </a>
                    </td>
                  </tr>
                )}
              </tbody>
            </OrdersTable>
            <ControlsContainer>
              <Button
                disabled={page === 1}
                onClick={() => this.setState({ page: Math.max(page - 1, 1) }, this.fetchOrders)}
              >
                Previous
              </Button>
              <p>Page {page}</p>
              <Button
                disabled={!orders || orders.length !== 50}
                onClick={() => this.setState({ page: page + 1 }, this.fetchOrders)}
              >
                Next
              </Button>
            </ControlsContainer>
          </>
        }
      </Root>
    )
  }

  fetchOrders = async () => {
    const { selectedPremisesID, page } = this.state

    if (!selectedPremisesID) {
      return
    }

    const orders = await AnitaAPI.Merchant.getOrderHistoryForPremises(selectedPremisesID, page)
    this.setState({ orders }, () => {
      if (!this.rootRef.current) return
      this.rootRef.current.scrollTop = 0
    })
  }
}

const Root = Styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  padding: 0 16px 32px 16px;
`

const PremisesPicker = Styled.select`
  margin-bottom: 16px;
`

const OrdersTable = Styled.table`
  margin: 16px 0;

  border: 1px solid #000;
  border-collapse: collapse;

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  tr:hover {
    background-color: #eee;
  }
`

const ControlsContainer = Styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-height: 50px;
`

const Button = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;

  background-color: #fff;
  border: 1px solid #000;
  font-weight: bold;
  cursor: pointer;
  opacity: ${props => props.disabled ? 0.25 : 1};
  pointer-events: ${props => props.disabled ? "none" : "auto"};

  &:hover {
    background-color: #008800;
    color: #fff;
  }

  @media (hover: none) {
    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
`
