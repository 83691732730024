import React from 'react'
import { Link } from 'react-router-dom'
import Styled from 'styled-components'

import AnitaAPI from '../lib/AnitaAPI'
import AppContext from '../contexts/app'

import {
  Widget,
  WidgetContainer,
  Spacer,
  Label,
  Input,
  ErrorMessage,
  Button,
  Footnote
} from '../components/Form'

export default class SignUp extends React.Component {
  static contextType = AppContext

  state = {
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    errors: {}
  }

  render() {
    return (
      <Container>
        <h1>Join Anita</h1>
        <Spacer />
        <SignUpForm onSubmit={this.onSubmit}>
          <WidgetContainer>
            <Widget>
              <Label>Email address</Label>
              <Input 
                type='email' 
                name='email' 
                value={this.state.email} 
                onChange={this.onInputChange} 
              />
              <ErrorMessage>{this.state.errors.email}</ErrorMessage>
            </Widget>
            <Spacer />
            <Widget>
              <Label>First name</Label>
              <Input 
                type='text' 
                name='firstName' 
                value={this.state.firstName}
                onChange={this.onInputChange}
              />
              <ErrorMessage>{this.state.errors.firstName}</ErrorMessage>
            </Widget>
            <Spacer />
            <Widget>
              <Label>Last name</Label>
              <Input 
                type='text' 
                name='lastName' 
                value={this.state.lastName}
                onChange={this.onInputChange}
              />
              <ErrorMessage>{this.state.errors.lastName}</ErrorMessage>
            </Widget>
            <Spacer />
            <Widget>
              <Label>Phone number</Label>
              <Input
                type='text'
                name='phoneNumber'
                value={this.state.phoneNumber}
                onChange={this.onInputChange}
              />
              <ErrorMessage>{this.state.errors.phoneNumber}</ErrorMessage>
            </Widget>
            <Spacer />
            <Widget>
              <Label>Password</Label>
              <Input 
                type='password' 
                name='password' 
                value={this.state.password}
                onChange={this.onInputChange}
              />
              <ErrorMessage>{this.state.errors.password}</ErrorMessage>
            </Widget>
            <Spacer />
            <Widget>
              <Label>Confirm password</Label>
              <Input 
                type='password' 
                name='confirmPassword' 
                value={this.state.confirmPassword}
                onChange={this.onInputChange}
              />
              <ErrorMessage>{this.state.errors.confirmPassword}</ErrorMessage>
            </Widget>
            <Spacer />
            <Button>Create your account</Button>
          </WidgetContainer>
        </SignUpForm>
        <Spacer />
        <Footnote>Already have an account? <Link to='/log-in'>Log in</Link></Footnote>
      </Container>
    )
  }
  
  onSubmit = async event => {
    event.preventDefault()

    const { email, password, confirmPassword, firstName, lastName, phoneNumber } = this.state

    if (password !== confirmPassword) {
      this.setState({ errors: { ...this.state.errors, confirmPassword: `Passwords do not match.` } })
      return
    }

    let account

    try {
      account = await AnitaAPI.Account.create(email, password, firstName, lastName, phoneNumber)
    } catch (error) {
      if (error.parameters != null) {
        this.setState({ errors: { ...error.parameters } })
      } else {
        console.log(`?????`, error)
      }

      return
    }

    this.context.set({ account })
    this.props.history.replace('/merchant-registration')
  }

  onInputChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }
}

const Container = Styled.div`
  padding: 16px;
`

const SignUpForm = Styled.form`
  max-width: 512px;
  margin: auto;
  padding: 0 16px 0 16px;
`