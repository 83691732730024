import React from 'react'
import Styled from 'styled-components'

import AnitaAPI from '../lib/AnitaAPI'
import AppContext from '../contexts/app'

import {
  Button
} from '../components/Form'

import "./ManagePremises.css"

export default class ManagePremises extends React.Component {
  static contextType = AppContext

  state = {
    premises: [],
    selectedPremises: null
  }

  async componentDidMount() {
    const premises = await AnitaAPI.Merchant.getPremises()
    this.setState({ premises })
  }

  render() {
    const { selectedPremises } = this.state

    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column"
        }}
      >      
        <Container>
          <h1>Your premises</h1>
          { this.state.premises.length === 0 &&
            <>
              <p>You haven't added any premises yet.</p>
              <Button onClick={() => this.props.history.replace('/premises/add')}>Add your premises</Button>
            </>
          }
          { this.state.premises.length > 0 &&
            <PremisesList>
              {/* <PremisesListHeader>
                <Column>Name</Column>
                <Column>Address</Column>
              </PremisesListHeader> */}
              {this.state.premises.map((premises) => (
                // <PremisesListItem key={premises.id}>
                //   <Column>{premises.name}</Column>
                //   <Column>{premises.streetAddress}, {premises.city}, {premises.postcode}</Column>
                // </PremisesListItem>
                <div
                  key={premises.id}
                  className="premises"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 16,
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                    borderRadius: 4,
                    // backgroundColor: selectedPremises === premises ? "#008800" : "#fff",
                    // color: selectedPremises === premises ? "#fff" : "#000",
                    cursor: "pointer",
                    marginBottom: 16
                  }}
                  // onClick={() => this.setState({ selectedPremises: selectedPremises === premises ? null : premises })}
                  onClick={() => this.props.history.push(`/v2/editor/${premises.id}`)}
                >
                  <img 
                    src={premises.logo}
                    alt={premises.name}
                    style={{
                      width: 64,
                      height: 64,
                      borderRadius: 8,
                      objectFit: "cover",
                      marginRight: 8
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left"
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold"
                      }}
                    >
                      {premises.name}
                    </div>
                    <div
                      style={{
                        marginTop: 4,
                        fontSize: 14
                      }}
                    >
                      {premises.streetAddress}, {premises.city}, {premises.postcode}
                    </div>
                  </div>
                </div>
              ))}
            </PremisesList>
          }
          {/* <PageLink to='/premises/add'>Add premises</PageLink> */}
        </Container>
        {selectedPremises &&
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: 16,
              color: "#fff",
              backgroundColor: "#008800",
              fontWeight: "bold",
              fontSize: 14,
              cursor: "pointer"
            }}
            onClick={() => this.props.history.push(`/v2/editor/${selectedPremises.id}`)}
          >
            {/* <i class="material-icons">face</i> EDIT {selectedPremises.name.toUpperCase()} */}
            <i className="material-icons" style={{ marginRight: 8 }}>menu_book</i> EDIT MENU
          </div>
        }
      </div>
    )
  }
}

const Container = Styled.div`
  padding: 16px;
  flex: 1;
  overflow-y: auto;
`

const PremisesList = Styled.div`
  max-width: 768px;
  margin: 32px auto 32px auto;

  display: flex;
  flex-direction: column;
`
