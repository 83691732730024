import Styled from 'styled-components'

export const WidgetContainer = Styled.div`
  display: flex;
  flex-direction: column;
`

export const Widget = Styled.div`
  display: flex;
  flex-direction: column;
`

export const Spacer = Styled.div`
  height: 32px;
`

export const Label = Styled.label`
  flex: 1;

  margin-bottom: 8px;

  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
  font-size: 12px;
  color: #008800;
`

export const ErrorMessage = Styled.div`
  margin-top: 8px;
  color: #e74c3c;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
`

export const Input = Styled.input`
  flex: 3;

  padding: 8px 0 8px 0;

  border: none;
  border-radius: 0;
  border-bottom: 1px solid #888;

  font-size: 16px;
  font-weight: 600px; 

  :focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.05);
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  :invalid {
    background-color: rgba(196, 0, 0, 0.1);
    box-shadow: none;
  }

  transition: background-color .15s;
`

export const Button = Styled.button`
  padding: 16px;
  margin: auto;

  background-color: #fff;
  border: 1px solid #000;
  border-radius: 0;
  outline: none;

  color: #000;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;

  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);

  cursor: pointer;
  user-select: none;

  :hover, :focus {
    background-color: #008800;
    color: #fff;
  }

  :active {
    box-shadow: none;
  }

  transition: 
    background-color .15s,
    color .15s,
    box-shadow .15s;
`

export const Footnote = Styled.div`
  font-size: 12px;
`