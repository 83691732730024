import React from 'react'
import Styled from 'styled-components'

import AnitaAPI from '../lib/AnitaAPI'
import AppContext from '../contexts/app'

import {
  Widget,
  WidgetContainer,
  Spacer,
  Label,
  Input,
  ErrorMessage,
  Button
} from '../components/Form'

export default class MerchantRegistration extends React.Component {
  static contextType = AppContext

  state = {
    businessName: '',
    errors: {}
  }

  render() {
    return (
      <Container>
        <h1>Merchant registration</h1>
        <Spacer />
        <RegistrationForm onSubmit={this.onSubmit}>
          <WidgetContainer>
            <Widget>
              <Label>Business name</Label>
              <Input
                type='text'
                name='businessName'
                value={this.state.businessName}
                onChange={this.onInputChange}
              />
              <ErrorMessage>{this.state.errors.businessName}</ErrorMessage>
            </Widget>
            <Spacer />
            <Button>Complete registration</Button>
          </WidgetContainer>
        </RegistrationForm>
      </Container>
    )
  }

  onSubmit = async event => {
    event.preventDefault()

    const { businessName } = this.state

    try {
      await AnitaAPI.Merchant.register(businessName)
    } catch (err) {
      if (err.parameters != null) {
        this.setState({ errors: { ...err.parameters } })
      } else {
        console.log('unknown error', err)
      }

      return
    }

    const account = await AnitaAPI.Account.retrieve()

    this.context.set({ account })
    this.props.history.replace('/account')
  }

  onInputChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }
}

const Container = Styled.div`
  padding: 16px;
`

const RegistrationForm = Styled.form`
  max-width: 512px;
  margin: auto;
  padding: 0 16px 0 16px;
`