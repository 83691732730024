import React from 'react'
import Styled from 'styled-components'

export default function() {
  return (
    <Container>
      <h1>Not found</h1>
      <p>Whatever you were looking for is not here.</p>
    </Container>
  )
}

const Container = Styled.div`
  padding: 16px;
`