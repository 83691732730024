import React from 'react'
import { Link } from 'react-router-dom'
import Styled from 'styled-components'

import AnitaAPI from '../lib/AnitaAPI'
import AppContext from '../contexts/app'

import {
  Widget,
  WidgetContainer,
  Spacer,
  Label,
  Input,
  ErrorMessage,
  Button,
  Footnote
} from '../components/Form'

export default class LogIn extends React.Component {
  static contextType = AppContext

  state = {
    email: '',
    password: '',
    errors: {}
  }

  render() {
    return (
      <Container>
        <h1>Log in</h1>
        <Spacer />
        <LogInForm onSubmit={this.onSubmit}>
          <WidgetContainer>
            <Widget>
              <Label>Email address</Label>
              <Input
                type='email'
                name='email'
                value={this.state.email}
                onChange={this.onInputChange}
              />
              <ErrorMessage>{this.state.errors.email}</ErrorMessage>
            </Widget>
            <Spacer />
            <Widget>
              <Label>Password</Label>
              <Input
                type='password'
                name='password'
                value={this.state.password}
                onChange={this.onInputChange}
              />
              <ErrorMessage>{this.state.errors.password}</ErrorMessage>
            </Widget>
            <Spacer />
            <Button>Log in</Button>
          </WidgetContainer>
        </LogInForm>
        <Spacer />
        <Footnote>Forgot your password? <Link to='/forgot-password'>Reset your password</Link></Footnote>
        <Spacer />
      </Container>
    )
  }

  onSubmit = async event => {
    event.preventDefault()

    const { email, password } = this.state
    let account

    try {
      account = await AnitaAPI.Account.logIn(email, password)
    } catch (err) {
      if (err.parameters != null) {
        this.setState({ errors: { ...err.parameters } })
      } else {
        console.log('unknown error', err)
      }

      return
    }

    this.context.set({ account })

    if (account.merchant == null) {
      this.props.history.replace('/merchant-registration')
    } else {
      this.props.history.replace('/premises')
    }
  }

  onInputChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }
}

const Container = Styled.div`
  padding: 16px;
`

const LogInForm = Styled.form`
  max-width: 512px;
  margin: auto;
  padding: 0 16px 0 16px;
`