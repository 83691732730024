import React from 'react'
import AppContext from '../contexts/app'

import AnitaAPI from "../lib/AnitaAPI"

import {
  Button
} from '../components/Form'

const STRIPE_CLIENT_ID = 'ca_FF0G7tXFmssM2NCqtOZAf5zuPmOuBIiH'
const STRIPE_CONNECT_URL = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=https://merchants.anita-delivery.com/connect-stripe`
// const STRIPE_CLIENT_ID = 'ca_FF0G83HlFZImwtKDXReCBHlVddfO4jpk'
// const STRIPE_CONNECT_URL = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=http://localhost:3000/connect-stripe`

export default class Account extends React.Component {
  static contextType = AppContext

  render() {
    const { account } = this.context

    if (account == null) {
      return null
    }

    const hasStripeAccount = account.merchant.stripeAccountID != null

    return (
      <div style={{ flex: 1, padding: 16, flexDirection: "column" }}>
        <h1>Your account</h1>
        { hasStripeAccount &&
          <>
            <p>You have connected your Stripe account.</p>
            <a href='https://dashboard.stripe.com'>View your Stripe dashboard</a>
          </>
        }
        { !hasStripeAccount &&
          <>
            <p>You must connect a Stripe account to our platform before you can receive payments.</p>
            <Button onClick={() => window.location.assign(STRIPE_CONNECT_URL)}>
              Connect a Stripe account
            </Button>
          </>
        }
        <br />
        <Button
          style={{ marginTop: 64 }}
          onClick={async () => {
            await AnitaAPI.Account.logOut()
            this.context.set({ account: null })

            this.props.history.replace("/")
          }}
        >
          Log out
        </Button>
      </div>
    )
  }
}

