import React from 'react'
import QueryString from 'query-string'
import AnitaAPI from '../lib/AnitaAPI'

import AppContext from '../contexts/app'

export default class StripeConnect extends React.Component {
  static contextType = AppContext

  async componentDidMount() {
    if (!this.context.account || this.context.account.merchant.stripeAccountID) {
      return
    }

    const params = QueryString.parse(this.props.location.search)
    const hasParams = Object.keys(params).length > 0

    if (!hasParams || params.error) {
      this.props.history.push('/account')
      return
    }

    if (await AnitaAPI.Merchant.connectStripe(params.code)) {
      console.log('Connected Stripe account!')

      const account = await AnitaAPI.Account.retrieve()
      this.context.set({ account })

      this.props.history.push('/account')
    } else {
      console.log('Could not connect Stripe account')
    }
  }

  render() {
    return (
      <div style={{ padding: 16 }}>
        <h1>Connecting Stripe account</h1>
        <p>Please wait...</p>
      </div>
    )
  }
}