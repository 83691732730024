import React from "react";
import Modal from "react-modal";

import AnitaAPI from "../../lib/AnitaAPI";
import { MenuSyncError } from "../../lib/Errors";

import "./MenuEditorPage.css";
import AnitaLogo from "../../anita_logo_icon.png";

const isDev = process.env.NODE_ENV === "development";
const HOST = isDev
  ? "http://134.209.186.254:5643"
  : "https://api.anita-delivery.com";
const menuCheckMs = 1000 * 60 * 15;

export default class MenuEditorPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      premises: null,
      menu: null,
      selectedSection: null,
      selectedProduct: null,
      selectedProductImage: null,
      selectedProductImageFile: null,
      selectedProductName: "",
      selectedProductPrice: 0,
      selectedProductCategory: "",
      selectedProductOutOfStock: false,
      selectedProductSpecialOffer: false,
      searchText: "",
      searchResults: null,
      isEditingSection: false,
      selectedSectionTitle: "",
      selectedSectionImage: null,
      selectedSectionImageFile: null,
      selectedSectionPosition: 0,
      isDeletingSection: false,
      isDeletingProduct: false,
      isAddingSection: false,
      newSectionTitle: "",
      newSectionPosition: 0,
      newSectionImage: null,
      newSectionImageFile: null,
      isAddingProduct: false,
      newProductName: "",
      newProductPrice: 0,
      newProductCategory: "",
      newProductSpecialOffer: false,
      newProductImage: null,
      newProductImageFile: null,
      isLoading: false,
      viewingImage: null,
      isOutOfSync: false,
      currentHash: "",
    };

    this.photoInputRef = React.createRef();
    this.listRef = React.createRef();
    this.menuCheckInterval = null;
  }

  async componentDidMount() {
    Modal.setAppElement("#root");

    const premisesId = parseInt(this.props.match.params.id);

    try {
      const allPremises = await AnitaAPI.Merchant.getPremises();
      const { menu, hash } = await AnitaAPI.Merchant.getMenu(premisesId);

      this.setState({
        premises: allPremises.find((premises) => premises.id === premisesId),
        menu,
        currentHash: hash,
      });
      this.menuCheckInterval = window.setInterval(
        this.checkIfLatestMenu,
        menuCheckMs
      );
    } catch (error) {
      console.log("error getting menu", error);
    }
  }

  render() {
    const {
      menu,
      premises,
      selectedSection,
      selectedProduct,
      selectedProductImage,
      selectedProductImageFile,
      selectedProductName,
      selectedProductPrice,
      selectedProductCategory,
      selectedProductOutOfStock,
      selectedProductSpecialOffer,
      searchText,
      searchResults,
      // section related stuff
      isEditingSection,
      selectedSectionTitle,
      selectedSectionImage,
      selectedSectionImageFile,
      selectedSectionPosition,
      isDeletingSection,
      isDeletingProduct,
      isAddingSection,
      newSectionTitle,
      newSectionPosition,
      newSectionImage,
      newSectionImageFile,
      isAddingProduct,
      newProductName,
      newProductPrice,
      newProductCategory,
      newProductSpecialOffer,
      newProductImage,
      newProductImageFile,
      isLoading,
      viewingImage,
      isOutOfSync,
    } = this.state;

    if (!menu || !premises) {
      return <div style={{ flex: 1 }}>Please wait...</div>;
    }

    const selectedSectionSpecialOffers = selectedSection
      ? selectedSection.data.filter((product) => product.isSpecial).length
      : 0;

    const selectedSectionOutOfStock = selectedSection
      ? selectedSection.data.filter(
          (product) =>
            product.outOfStock ||
            (product.outOfStockUntil && product.outOfStockUntil > Date.now())
        ).length
      : 0;

    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: "#008800",
            padding: 8,
            borderBottom: "1px solid #fff",
          }}
        >
          <img
            src={AnitaLogo}
            alt="Anita Delivery"
            style={{
              width: 24,
              height: 24,
              padding: 4,
              backgroundColor: "#fff",
              borderRadius: 28,
              marginRight: 8,
            }}
          />
          <div
            style={{
              flex: 1,
              textAlign: "left",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            {premises.name}
          </div>
          <i
            className="material-icons"
            style={{
              color: "#fff",
              border: "2px solid #fff",
              borderRadius: 24,
              padding: 4,
              cursor: "pointer",
            }}
            onClick={() => this.props.history.push("/premises")}
          >
            home
          </i>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
          }}
        >
          <input
            className="search-bar"
            type="text"
            placeholder="Start typing here to search..."
            value={searchText}
            onChange={(event) => {
              this.onSearch(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.target.blur();
              }
            }}
          />
          <i
            className="material-icons"
            style={{
              color: "#000",
              padding: "0px 16px",
              cursor: "pointer",
              visibility: searchText.length > 0 ? "visible" : "hidden",
            }}
            onClick={() =>
              this.setState(
                { searchText: "", searchResults: null },
                () => (this.listRef.current.scrollTop = 0)
              )
            }
          >
            cancel
          </i>
        </div>
        {selectedSection != null && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: 12,
              borderBottom: "1px solid #ddd",
              fontWeight: "bold",
              fontSize: 16,
              backgroundColor: "#fff",
              color: "#000",
            }}
          >
            <i
              className="material-icons"
              style={{
                padding: 4,
                cursor: "pointer",
                border: "2px solid #000",
                borderRadius: 24,
              }}
              onClick={() => {
                this.setState(
                  { selectedSection: null },
                  () => (this.listRef.current.scrollTop = 0)
                );
              }}
            >
              {/* chevron_left */}
              arrow_back
            </i>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                marginLeft: 16,
              }}
            >
              <div>{selectedSection.title}</div>
              <div style={{ fontSize: 12, fontWeight: "normal" }}>
                <span>
                  {selectedSection.data.length}{" "}
                  {selectedSection.data.length === 1 ? "product" : "products"}
                </span>
                {selectedSectionSpecialOffers > 0 && (
                  <span style={{ marginLeft: 8, color: "#036bfc" }}>
                    {selectedSectionSpecialOffers}{" "}
                    {selectedSectionSpecialOffers === 1
                      ? "special offer"
                      : "special offers"}
                  </span>
                )}
                {selectedSectionOutOfStock > 0 && (
                  <span style={{ marginLeft: 8, color: "#eb4034" }}>
                    {selectedSectionOutOfStock} out of stock
                  </span>
                )}
              </div>
            </div>
            <i
              className="material-icons"
              style={{
                backgroundColor: "#fca503",
                padding: 12,
                borderRadius: 32,
                color: "#fff",
                marginLeft: 16,
                cursor: "pointer",
              }}
              onClick={() =>
                this.setState({
                  selectedSectionTitle: selectedSection.title,
                  selectedSectionPosition:
                    this.state.menu.indexOf(selectedSection),
                  isEditingSection: true,
                  selectedProductImage: null,
                  selectedProductImageFile: null,
                  selectedSectionImage: null,
                  selectedSectionImageFile: null,
                })
              }
            >
              edit
            </i>
          </div>
        )}
        <div ref={this.listRef} style={{ flex: 1, overflowY: "auto" }}>
          {searchResults != null && (
            <>
              {searchResults.map(({ product, section }) => {
                return (
                  <ProductEditor
                    key={product.id}
                    section={section}
                    product={product}
                    onProductSelected={(product) => {
                      this.setState({
                        selectedProduct: product,
                        selectedProductName: product.name,
                        selectedProductPrice: (
                          product.prices[0] / 100.0
                        ).toFixed(2),
                        selectedProductCategory: section.title,
                        selectedProductOutOfStock:
                          product.outOfStock ||
                          (product.outOfStockUntil &&
                            product.outOfStockUntil > Date.now()),
                        selectedProductSpecialOffer: product.isSpecial,
                        selectedProductImage: null,
                        selectedProductImageFile: null,
                      });
                    }}
                    isSelected={selectedProduct === product}
                    onImageClicked={(image) =>
                      this.setState({ viewingImage: image })
                    }
                  />
                );
              })}
            </>
          )}
          {selectedSection != null && (
            <>
              {selectedSection.data.map((product) => {
                return (
                  <ProductEditor
                    key={product.id}
                    section={selectedSection}
                    product={product}
                    onProductSelected={(product) => {
                      this.setState({
                        selectedProduct: product,
                        selectedProductName: product.name,
                        selectedProductPrice: (
                          product.prices[0] / 100.0
                        ).toFixed(2),
                        selectedProductCategory: selectedSection.title,
                        selectedProductOutOfStock:
                          product.outOfStock ||
                          (product.outOfStockUntil &&
                            product.outOfStockUntil > Date.now()),
                        selectedProductSpecialOffer: product.isSpecial,
                        selectedProductImage: null,
                        selectedProductImageFile: null,
                      });
                    }}
                    isSelected={selectedProduct === product}
                    onImageClicked={(image) =>
                      this.setState({ viewingImage: image })
                    }
                  />
                );
              })}
            </>
          )}
          {!searchText &&
            !selectedSection &&
            menu.map((section, index) => {
              const sectionSpecialOffers = section.data.filter(
                (product) => product.isSpecial
              ).length;
              const sectionOutOfStock = section.data.filter(
                (product) =>
                  product.outOfStock ||
                  (product.outOfStockUntil &&
                    product.outOfStockUntil > Date.now())
              ).length;

              return (
                <div key={index}>
                  <div
                    className="category-item"
                    onClick={(event) => {
                      this.setState(
                        {
                          selectedSection:
                            selectedSection === section ? null : section,
                        },
                        () => (this.listRef.current.scrollTop = 0)
                      );
                    }}
                  >
                    <img
                      src={
                        section.image
                          ? `${HOST}/static/images/${section.image}`
                          : ``
                      }
                      alt=""
                      style={{
                        width: 48,
                        height: 48,
                        objectFit: "contain",
                        marginRight: 8,
                        cursor: "pointer",
                      }}
                    />
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        marginRight: 8,
                      }}
                    >
                      <div>{section.title}</div>
                      <div style={{ fontSize: 12, fontWeight: "normal" }}>
                        <span>
                          {section.data.length}{" "}
                          {section.data.length === 1 ? "product" : "products"}
                        </span>
                        {sectionSpecialOffers > 0 && (
                          <span style={{ marginLeft: 8, color: "#036bfc" }}>
                            {sectionSpecialOffers}{" "}
                            {sectionSpecialOffers === 1
                              ? "special offer"
                              : "special offers"}
                          </span>
                        )}
                        {sectionOutOfStock > 0 && (
                          <span style={{ marginLeft: 8, color: "#eb4034" }}>
                            {sectionOutOfStock} out of stock
                          </span>
                        )}
                      </div>
                    </div>
                    <i className="material-icons">chevron_right</i>
                  </div>
                </div>
              );
            })}
        </div>
        {!searchText && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#008800",
              borderTop: "1px solid #ccc",
              color: "#fff",
              fontWeight: "bold",
              fontSize: 14,
              padding: 12,
              cursor: "pointer",
            }}
            onClick={() => {
              if (selectedSection) {
                this.setState({
                  isAddingProduct: true,
                  newProductName: "",
                  newProductPrice: 0,
                  newProductCategory: selectedSection.title,
                  newProductImage: null,
                  newProductImageFile: null,
                });
              } else {
                this.setState({
                  isAddingSection: true,
                  newSectionTitle: "",
                  newSectionPosition: menu.length,
                });
              }
            }}
          >
            {selectedSection != null ? (
              <>
                <i className="material-icons" style={{ marginRight: 8 }}>
                  add_circle_outline
                </i>
                ADD A NEW PRODUCT
              </>
            ) : (
              <>
                <i className="material-icons" style={{ marginRight: 8 }}>
                  list
                </i>
                ADD A NEW SECTION
              </>
            )}
          </div>
        )}
        <Modal
          isOpen={selectedProduct != null}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          onRequestClose={() =>
            this.setState({ isDeletingProduct: false, selectedProduct: null })
          }
          style={{
            content: {
              display: "flex",
              flexDirection: "column",
              padding: 16,
              maxWidth: 350,
              maxHeight: isDeletingProduct ? 250 : 500,
              margin: "auto",
              top: 16,
              left: 16,
              bottom: 16,
              right: 16,
            },
          }}
        >
          {isDeletingProduct ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 8,
                }}
              >
                <div style={{ fontWeight: "bold", fontSize: 16, flex: 1 }}>
                  Delete product
                </div>
                <i
                  className="material-icons"
                  style={{ padding: 4, cursor: "pointer" }}
                  onClick={() => {
                    if (isLoading) {
                      return;
                    }

                    this.setState({ isDeletingProduct: false });
                  }}
                >
                  cancel
                </i>
              </div>
              <div style={{ flex: 1, marginTop: 12 }}>
                Are you sure you want to delete{" "}
                <strong>{selectedProductName}</strong>? This action cannot be
                undone.
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 12,
                  backgroundColor: "#880000",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: 14,
                  marginTop: 16,
                  borderRadius: 4,
                  cursor: "pointer",
                  opacity: isLoading ? 0.5 : 1,
                }}
                onClick={async () => {
                  if (isLoading) {
                    return;
                  }

                  const updatedSection = {
                    ...selectedSection,
                    data: selectedSection.data.filter(
                      (product) => product !== selectedProduct
                    ),
                  };

                  const updatedMenu = [...this.state.menu];

                  const sectionIndex = updatedMenu.findIndex(
                    (section) => section === selectedSection
                  );
                  updatedMenu[sectionIndex] = updatedSection;

                  this.setState({ isLoading: true });

                  try {
                    await this.updateMenu(updatedMenu);
                  } catch (error) {
                    if (error instanceof MenuSyncError) {
                      return this.setState({ isOutOfSync: true });
                    }

                    alert(`Unable to delete product. ${error.message}`);

                    this.setState({
                      isLoading: false,
                    });

                    return;
                  }

                  this.setState({
                    selectedProduct: null,
                    isDeletingProduct: false,
                    isLoading: false,
                    menu: updatedMenu,
                    selectedSection: updatedSection,
                  });
                }}
              >
                {isLoading ? "PLEASE WAIT" : "YES"}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 12,
                  backgroundColor: "#008800",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: 14,
                  marginTop: 12,
                  borderRadius: 4,
                  cursor: "pointer",
                  opacity: isLoading ? 0.5 : 1,
                }}
                onClick={() => {
                  if (isLoading) {
                    return;
                  }

                  this.setState({ isDeletingProduct: false });
                }}
              >
                {isLoading ? "PLEASE WAIT" : "NO"}
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 8,
                }}
              >
                <div style={{ fontWeight: "bold", fontSize: 16, flex: 1 }}>
                  Edit product
                </div>
                <i
                  className="material-icons"
                  style={{ padding: 4, cursor: "pointer" }}
                  onClick={() => {
                    if (isLoading) return;

                    this.setState({ selectedProduct: null });
                  }}
                >
                  cancel
                </i>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {selectedProduct && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={
                          selectedProductImage
                            ? selectedProductImage
                            : selectedProduct.image
                            ? `${HOST}/static/images/${selectedProduct.image}`
                            : ``
                        }
                        alt=""
                        style={{
                          width: 64,
                          height: 64,
                          objectFit: "contain",
                          border: "1px solid rgba(0, 0, 0, 0.2)",
                          padding: 4,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (selectedProductImage) {
                            this.setState({
                              viewingImage: selectedProductImage,
                            });
                          } else if (selectedProduct.image) {
                            this.setState({
                              viewingImage: `${HOST}/static/images/${selectedProduct.image}`,
                            });
                          }
                        }}
                      />
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            backgroundColor: "#008800",
                            padding: 8,
                            borderRadius: 4,
                            fontSize: 12,
                            fontWeight: "bold",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                          onClick={() => this.photoInputRef.current.click()}
                        >
                          <i
                            className="material-icons"
                            style={{ marginRight: 8 }}
                          >
                            add_a_photo
                          </i>
                          CHANGE PHOTO
                          <input
                            type="file"
                            accept="image/*"
                            ref={this.photoInputRef}
                            style={{ display: "none" }}
                            onChange={() => {
                              const files = this.photoInputRef.current.files;

                              if (!files || files.length === 0) {
                                return;
                              }

                              const file = files[0];
                              const fileReader = new FileReader();

                              fileReader.onload = (event) => {
                                const dataUrl = event.target.result;

                                this.setState({
                                  selectedProductImage: dataUrl,
                                  selectedProductImageFile: file,
                                });
                              };

                              fileReader.readAsDataURL(file);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 8,
                      }}
                    >
                      <div
                        style={{
                          color: "#008800",
                          fontSize: 12,
                          fontWeight: "bold",
                          marginBottom: 4,
                        }}
                      >
                        NAME
                      </div>
                      <textarea
                        style={{
                          borderRadius: 0,
                          border: "1px solid rgba(0, 0, 0, 0.2)",
                          fontSize: 16,
                          padding: "8px 4px",
                          height: "auto",
                        }}
                        rows={2}
                        onChange={(event) =>
                          this.setState({
                            selectedProductName: event.target.value,
                          })
                        }
                        value={selectedProductName}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 8,
                      }}
                    >
                      <div
                        style={{
                          color: "#008800",
                          fontSize: 12,
                          fontWeight: "bold",
                          marginBottom: 4,
                        }}
                      >
                        PRICE
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontWeight: "bold", marginRight: 2 }}>
                          £
                        </div>
                        <input
                          type="number"
                          style={{
                            borderRadius: 0,
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                            fontSize: 16,
                            padding: "8px 4px",
                            flex: 1,
                          }}
                          value={selectedProductPrice}
                          onChange={(event) =>
                            this.setState({
                              selectedProductPrice: event.target.value,
                            })
                          }
                          min={0}
                          step={0.1}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 8,
                      }}
                    >
                      <div
                        style={{
                          color: "#008800",
                          fontSize: 12,
                          fontWeight: "bold",
                          marginBottom: 4,
                        }}
                      >
                        CATEGORY
                      </div>
                      <select
                        style={{
                          display: "flex",
                          flex: 1,
                          fontSize: 16,
                        }}
                        value={selectedProductCategory}
                        onChange={(event) =>
                          this.setState({
                            selectedProductCategory: event.target.value,
                          })
                        }
                      >
                        {menu.map((category) => (
                          <option key={category.title} value={category.title}>
                            {category.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 8,
                      }}
                    >
                      <input
                        id="special-offer"
                        type="checkbox"
                        style={{
                          width: 24,
                          height: 24,
                          cursor: "pointer",
                        }}
                        checked={selectedProductSpecialOffer}
                        onChange={(event) =>
                          this.setState({
                            selectedProductSpecialOffer: event.target.checked,
                          })
                        }
                      />
                      <label
                        htmlFor="special-offer"
                        style={{
                          flex: 1,
                          fontWeight: "bold",
                          marginLeft: 8,
                          cursor: "pointer",
                        }}
                      >
                        Special offer (highlight product)
                      </label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 8,
                      }}
                    >
                      <input
                        id="out-of-stock"
                        type="checkbox"
                        style={{
                          width: 24,
                          height: 24,
                          cursor: "pointer",
                        }}
                        checked={selectedProductOutOfStock}
                        onChange={(event) =>
                          this.setState({
                            selectedProductOutOfStock: event.target.checked,
                          })
                        }
                      />
                      <label
                        htmlFor="out-of-stock"
                        style={{
                          flex: 1,
                          fontWeight: "bold",
                          marginLeft: 8,
                          cursor: "pointer",
                        }}
                      >
                        Out of stock (hide product)
                      </label>
                    </div>
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 12,
                  backgroundColor: "#008800",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: 14,
                  marginTop: 16,
                  borderRadius: 4,
                  cursor: "pointer",
                  opacity: isLoading ? 0.5 : 1,
                }}
                onClick={async () => {
                  if (isLoading) return;

                  if (!selectedProductName) {
                    return alert("Please enter a name for this product.");
                  }

                  const price = parseInt(
                    parseFloat(selectedProductPrice) * 100
                  );

                  if (isNaN(price)) {
                    return alert(
                      "Please enter a valid price for this product."
                    );
                  }

                  this.setState({ isLoading: true });

                  let image = selectedProduct.image;

                  if (selectedProductImageFile) {
                    try {
                      image = await AnitaAPI.Merchant.uploadPhoto(
                        this.state.premises.id,
                        selectedProductImageFile
                      );

                      if (!image) {
                        this.setState({ isLoading: false });
                        return alert("Upload failed.");
                      } else {
                        console.log("uploaded image", image);
                      }
                    } catch (error) {
                      this.setState({ isLoading: false });
                      console.log("error uploading image", error);
                      return alert(`Upload failed. ${error.message}`);
                    }
                  }

                  const updatedProduct = {
                    ...selectedProduct,
                    name: selectedProductName,
                    prices: [price, price],
                    outOfStock: selectedProductOutOfStock,
                    isSpecial: selectedProductSpecialOffer,
                    image,
                  };

                  let menu = [...this.state.menu];
                  let updatedSection;

                  if (selectedSection) {
                    updatedSection = {
                      ...selectedSection,
                      data: selectedSection.data.filter(
                        (product) => product.id !== selectedProduct.id
                      ),
                    };

                    const index = menu.findIndex(
                      (section) => section.title === selectedSection.title
                    );
                    menu[index] = updatedSection;
                  } else {
                    menu = menu.map((section) => {
                      return {
                        ...section,
                        data: section.data.filter(
                          (product) => product.id !== selectedProduct.id
                        ),
                      };
                    });
                  }

                  for (const section of menu) {
                    if (section.title === selectedProductCategory) {
                      section.data.push(updatedProduct);
                      section.data.sort((a, b) => a.name.localeCompare(b.name));
                    }
                  }

                  this.setState({ isLoading: true });

                  try {
                    await this.updateMenu(menu);
                  } catch (error) {
                    if (error instanceof MenuSyncError) {
                      return this.setState({ isOutOfSync: true });
                    }

                    alert(`Unable to save product. ${error.message}`);

                    this.setState({
                      isLoading: false,
                    });

                    return;
                  }

                  this.setState({
                    menu,
                    selectedProduct: null,
                    selectedSection: updatedSection,
                    isLoading: false,
                  });

                  if (this.state.searchResults) {
                    this.onSearch(this.state.searchText);
                  }
                }}
              >
                {isLoading ? "PLEASE WAIT" : "SAVE PRODUCT"}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 12,
                  backgroundColor: "#880000",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: 14,
                  marginTop: 12,
                  borderRadius: 4,
                  cursor: "pointer",
                  opacity: isLoading ? 0.5 : 1,
                }}
                onClick={() => {
                  if (isLoading) return;

                  this.setState({ isDeletingProduct: true });
                }}
              >
                {isLoading ? "PLEASE WAIT" : "DELETE PRODUCT"}
              </div>
            </>
          )}
        </Modal>
        <Modal
          isOpen={isAddingProduct}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          onRequestClose={() => this.setState({ isAddingProduct: false })}
          style={{
            content: {
              display: "flex",
              flexDirection: "column",
              padding: 16,
              maxWidth: 350,
              maxHeight: 500,
              margin: "auto",
              top: 16,
              left: 16,
              bottom: 16,
              right: 16,
            },
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 8,
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: 16, flex: 1 }}>
              Add a new product
            </div>
            <i
              className="material-icons"
              style={{ padding: 4, cursor: "pointer" }}
              onClick={() => {
                if (isLoading) {
                  return;
                }

                this.setState({ isAddingProduct: false });
              }}
            >
              cancel
            </i>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img
                src={newProductImage}
                alt=""
                style={{
                  width: 64,
                  height: 64,
                  objectFit: "contain",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  padding: 4,
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (newProductImage) {
                    this.setState({ viewingImage: newProductImage });
                  }
                }}
              />
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "#008800",
                    padding: 8,
                    borderRadius: 4,
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => this.photoInputRef.current.click()}
                >
                  <i className="material-icons" style={{ marginRight: 8 }}>
                    add_a_photo
                  </i>
                  CHANGE PHOTO
                  <input
                    type="file"
                    accept="image/*"
                    ref={this.photoInputRef}
                    style={{ display: "none" }}
                    onChange={() => {
                      const files = this.photoInputRef.current.files;

                      if (!files || files.length === 0) {
                        return;
                      }

                      const file = files[0];
                      const fileReader = new FileReader();

                      fileReader.onload = (event) => {
                        const dataUrl = event.target.result;

                        this.setState({
                          newProductImage: dataUrl,
                          newProductImageFile: file,
                        });
                      };

                      fileReader.readAsDataURL(file);
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 8,
              }}
            >
              <div
                style={{
                  color: "#008800",
                  fontSize: 12,
                  fontWeight: "bold",
                  marginBottom: 4,
                }}
              >
                NAME
              </div>
              <textarea
                style={{
                  borderRadius: 0,
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  fontSize: 16,
                  padding: "8px 4px",
                  height: "auto",
                }}
                rows={2}
                onChange={(event) =>
                  this.setState({ newProductName: event.target.value })
                }
                value={newProductName}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 8,
              }}
            >
              <div
                style={{
                  color: "#008800",
                  fontSize: 12,
                  fontWeight: "bold",
                  marginBottom: 4,
                }}
              >
                PRICE
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ fontWeight: "bold", marginRight: 2 }}>£</div>
                <input
                  type="number"
                  style={{
                    borderRadius: 0,
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                    fontSize: 16,
                    padding: "8px 4px",
                    flex: 1,
                  }}
                  value={newProductPrice}
                  onChange={(event) =>
                    this.setState({ newProductPrice: event.target.value })
                  }
                  min={0}
                  step={0.1}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 8,
              }}
            >
              <div
                style={{
                  color: "#008800",
                  fontSize: 12,
                  fontWeight: "bold",
                  marginBottom: 4,
                }}
              >
                CATEGORY
              </div>
              <select
                style={{
                  display: "flex",
                  flex: 1,
                  fontSize: 16,
                }}
                value={newProductCategory}
                onChange={(event) =>
                  this.setState({ newProductCategory: event.target.value })
                }
              >
                {menu.map((category) => (
                  <option key={category.title} value={category.title}>
                    {category.title}
                  </option>
                ))}
              </select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: 8,
              }}
            >
              <input
                id="special-offer"
                type="checkbox"
                style={{
                  width: 24,
                  height: 24,
                  cursor: "pointer",
                }}
                checked={newProductSpecialOffer}
                onChange={(event) =>
                  this.setState({
                    newProductSpecialOffer: event.target.checked,
                  })
                }
              />
              <label
                htmlFor="special-offer"
                style={{
                  flex: 1,
                  fontWeight: "bold",
                  marginLeft: 8,
                  cursor: "pointer",
                }}
              >
                Special offer
              </label>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 12,
              backgroundColor: "#008800",
              color: "#fff",
              fontWeight: "bold",
              fontSize: 14,
              marginTop: 12,
              borderRadius: 4,
              cursor: "pointer",
              opacity: isLoading ? 0.5 : 1,
            }}
            onClick={async () => {
              if (isLoading) {
                return;
              }

              if (!newProductName) {
                return alert("Please enter a name for this product.");
              }

              const price = parseInt(parseFloat(newProductPrice) * 100);

              if (isNaN(price)) {
                return alert("Please enter a valid price for this product.");
              }

              this.setState({ isLoading: true });

              let image;

              if (newProductImageFile) {
                try {
                  image = await AnitaAPI.Merchant.uploadPhoto(
                    this.state.premises.id,
                    newProductImageFile
                  );

                  if (!image) {
                    this.setState({ isLoading: false });
                    return alert("Upload failed.");
                  } else {
                    console.log("uploaded image", image);
                  }
                } catch (error) {
                  this.setState({ isLoading: false });
                  console.log("error uploading image", error);
                  return alert(`Upload failed. ${error.message}`);
                }
              }

              const newProduct = {
                id: Date.now().toString(),
                name: newProductName,
                prices: [price, price],
                outOfStock: false,
                isSpecial: newProductSpecialOffer,
                image,
              };

              const menu = [
                ...this.state.menu.map((section) => ({
                  ...section,
                  data: [...section.data],
                })),
              ];

              for (const section of menu) {
                if (section.title === newProductCategory) {
                  section.data.push(newProduct);
                  section.data.sort((a, b) => a.name.localeCompare(b.name));
                }
              }

              this.setState({ isLoading: true });

              try {
                await this.updateMenu(menu);
              } catch (error) {
                if (error instanceof MenuSyncError) {
                  return this.setState({ isOutOfSync: true });
                }

                alert(`Unable to save product. ${error.message}`);

                this.setState({
                  isLoading: false,
                });

                return;
              }

              selectedSection.data.push(newProduct);
              selectedSection.data.sort((a, b) => a.name.localeCompare(b.name));

              this.setState({ menu, isAddingProduct: false, isLoading: false });
            }}
          >
            {isLoading ? "PLEASE WAIT" : "SAVE PRODUCT"}
          </div>
        </Modal>
        <Modal
          isOpen={selectedSection != null && isEditingSection}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          onRequestClose={() =>
            this.setState({ isDeletingSection: false, isEditingSection: false })
          }
          style={{
            content: {
              display: "flex",
              flexDirection: "column",
              padding: 16,
              maxWidth: 350,
              maxHeight: isDeletingSection ? 250 : 300,
              margin: "auto",
              top: 16,
              left: 16,
              bottom: 16,
              right: 16,
            },
          }}
        >
          {!isDeletingSection ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 8,
                }}
              >
                <div style={{ fontWeight: "bold", fontSize: 16, flex: 1 }}>
                  Edit section
                </div>
                <i
                  className="material-icons"
                  style={{ padding: 4, cursor: "pointer" }}
                  onClick={() => {
                    if (isLoading) {
                      return;
                    }

                    this.setState({ isEditingSection: false });
                  }}
                >
                  cancel
                </i>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {selectedSection && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={
                          selectedSectionImage
                            ? selectedSectionImage
                            : selectedSection.image
                            ? `${HOST}/static/images/${selectedSection.image}`
                            : ``
                        }
                        alt=""
                        style={{
                          width: 64,
                          height: 64,
                          objectFit: "contain",
                          border: "1px solid rgba(0, 0, 0, 0.2)",
                          padding: 4,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (selectedSectionImage) {
                            this.setState({
                              viewingImage: selectedSectionImage,
                            });
                          } else if (selectedSection.image) {
                            this.setState({
                              viewingImage: `${HOST}/static/images/${selectedSection.image}`,
                            });
                          }
                        }}
                      />
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            backgroundColor: "#008800",
                            padding: 8,
                            borderRadius: 4,
                            fontSize: 12,
                            fontWeight: "bold",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                          onClick={() => this.photoInputRef.current.click()}
                        >
                          <i
                            className="material-icons"
                            style={{ marginRight: 8 }}
                          >
                            add_a_photo
                          </i>
                          CHANGE PHOTO
                          <input
                            type="file"
                            accept="image/*"
                            ref={this.photoInputRef}
                            style={{ display: "none" }}
                            onChange={() => {
                              const files = this.photoInputRef.current.files;

                              if (!files || files.length === 0) {
                                return;
                              }

                              const file = files[0];
                              const fileReader = new FileReader();

                              fileReader.onload = (event) => {
                                const dataUrl = event.target.result;

                                this.setState({
                                  selectedSectionImage: dataUrl,
                                  selectedSectionImageFile: file,
                                });
                              };

                              fileReader.readAsDataURL(file);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 8,
                      }}
                    >
                      <div
                        style={{
                          color: "#008800",
                          fontSize: 12,
                          fontWeight: "bold",
                          marginBottom: 4,
                        }}
                      >
                        TITLE
                      </div>
                      <textarea
                        style={{
                          borderRadius: 0,
                          border: "1px solid rgba(0, 0, 0, 0.2)",
                          fontSize: 16,
                          padding: "8px 4px",
                          height: "auto",
                        }}
                        rows={2}
                        onChange={(event) =>
                          this.setState({
                            selectedSectionTitle: event.target.value,
                          })
                        }
                        value={selectedSectionTitle}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 8,
                      }}
                    >
                      <div
                        style={{
                          color: "#008800",
                          fontSize: 12,
                          fontWeight: "bold",
                          marginBottom: 4,
                        }}
                      >
                        POSITION
                      </div>
                      <select
                        style={{
                          display: "flex",
                          flex: 1,
                          fontSize: 16,
                        }}
                        value={selectedSectionPosition}
                        onChange={(event) =>
                          this.setState({
                            selectedSectionPosition: event.target.value,
                          })
                        }
                      >
                        {menu.map((category, index) => (
                          <option key={index} value={index}>
                            {index + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 12,
                  backgroundColor: "#008800",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: 14,
                  marginTop: 8,
                  borderRadius: 4,
                  cursor: "pointer",
                  opacity: isLoading ? 0.5 : 1,
                }}
                onClick={async () => {
                  const title = this.state.selectedSectionTitle.trim();

                  if (!title) {
                    return alert("Please provide a title for this section.");
                  }

                  let image = selectedSection.image;

                  if (selectedSectionImageFile) {
                    try {
                      image = await AnitaAPI.Merchant.uploadPhoto(
                        this.state.premises.id,
                        selectedSectionImageFile
                      );

                      if (!image) {
                        this.setState({ isLoading: false });
                        return alert("Upload failed.");
                      } else {
                        console.log("uploaded image", image);
                      }
                    } catch (error) {
                      this.setState({ isLoading: false });
                      console.log("error uploading image", error);
                      return alert(`Upload failed. ${error.message}`);
                    }
                  }

                  const updatedSection = {
                    ...selectedSection,
                    title,
                    image,
                  };

                  const menu = this.state.menu.filter(
                    (section) => section !== selectedSection
                  );
                  menu.splice(
                    this.state.selectedSectionPosition,
                    0,
                    updatedSection
                  );

                  this.setState({ isLoading: true });

                  try {
                    await this.updateMenu(menu);
                  } catch (error) {
                    if (error instanceof MenuSyncError) {
                      return this.setState({ isOutOfSync: true });
                    }

                    alert(`Unable to save section. ${error.message}`);

                    this.setState({
                      isLoading: false,
                    });

                    return;
                  }

                  this.setState({
                    menu,
                    selectedSection: updatedSection,
                    isEditingSection: false,
                    isLoading: false,
                  });
                }}
              >
                {isLoading ? "PLEASE WAIT" : "SAVE SECTION"}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 12,
                  backgroundColor: "#880000",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: 14,
                  marginTop: 12,
                  borderRadius: 4,
                  cursor: "pointer",
                }}
                onClick={() => this.setState({ isDeletingSection: true })}
              >
                DELETE SECTION
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 8,
                }}
              >
                <div style={{ fontWeight: "bold", fontSize: 16, flex: 1 }}>
                  Delete section
                </div>
                <i
                  className="material-icons"
                  style={{ padding: 4, cursor: "pointer" }}
                  onClick={() => {
                    if (isLoading) {
                      return;
                    }

                    this.setState({ isDeletingSection: false });
                  }}
                >
                  cancel
                </i>
              </div>
              <div style={{ flex: 1, marginTop: 12 }}>
                Are you sure you want to delete{" "}
                <strong>{selectedSectionTitle}</strong>? This action cannot be
                undone.
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 12,
                  backgroundColor: "#880000",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: 14,
                  marginTop: 16,
                  borderRadius: 4,
                  cursor: "pointer",
                  opacity: isLoading ? 0.5 : 1,
                }}
                onClick={async () => {
                  if (isLoading) {
                    return;
                  }

                  const updatedMenu = this.state.menu.filter(
                    (section) => section !== selectedSection
                  );
                  this.setState({ isLoading: true });

                  try {
                    await this.updateMenu(updatedMenu);
                  } catch (error) {
                    if (error instanceof MenuSyncError) {
                      return this.setState({ isOutOfSync: true });
                    }

                    alert(`Unable to delete section. ${error.message}`);

                    this.setState({
                      isLoading: false,
                    });

                    return;
                  }

                  this.setState({
                    menu: updatedMenu,
                    selectedSection: null,
                    isDeletingSection: false,
                    isEditingSection: false,
                    isLoading: false,
                  });
                }}
              >
                {isLoading ? "PLEASE WAIT" : "YES"}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 12,
                  backgroundColor: "#008800",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: 14,
                  marginTop: 12,
                  borderRadius: 4,
                  cursor: "pointer",
                  opacity: isLoading ? 0.5 : 1,
                }}
                onClick={() => {
                  if (isLoading) {
                    return;
                  }

                  this.setState({ isDeletingSection: false });
                }}
              >
                {isLoading ? "PLEASE WAIT" : "NO"}
              </div>
            </>
          )}
        </Modal>
        <Modal
          isOpen={isAddingSection}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          onRequestClose={() => this.setState({ isAddingSection: false })}
          style={{
            content: {
              display: "flex",
              flexDirection: "column",
              padding: 16,
              maxWidth: 350,
              maxHeight: 300,
              margin: "auto",
              top: 16,
              left: 16,
              bottom: 16,
              right: 16,
            },
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 8,
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: 16, flex: 1 }}>
              Add a new section
            </div>
            <i
              className="material-icons"
              style={{ padding: 4, cursor: "pointer" }}
              onClick={() => {
                if (isLoading) {
                  return;
                }

                this.setState({ isAddingSection: false });
              }}
            >
              cancel
            </i>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 8,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  src={newSectionImage}
                  alt=""
                  style={{
                    width: 64,
                    height: 64,
                    objectFit: "contain",
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                    padding: 4,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (newSectionImage) {
                      this.setState({ viewingImage: newSectionImage });
                    }
                  }}
                />
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      backgroundColor: "#008800",
                      padding: 8,
                      borderRadius: 4,
                      fontSize: 12,
                      fontWeight: "bold",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={() => this.photoInputRef.current.click()}
                  >
                    <i className="material-icons" style={{ marginRight: 8 }}>
                      add_a_photo
                    </i>
                    CHANGE PHOTO
                    <input
                      type="file"
                      accept="image/*"
                      ref={this.photoInputRef}
                      style={{ display: "none" }}
                      onChange={() => {
                        const files = this.photoInputRef.current.files;

                        if (!files || files.length === 0) {
                          return;
                        }

                        const file = files[0];
                        const fileReader = new FileReader();

                        fileReader.onload = (event) => {
                          const dataUrl = event.target.result;

                          this.setState({
                            newSectionImage: dataUrl,
                            newSectionImageFile: file,
                          });
                        };

                        fileReader.readAsDataURL(file);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  color: "#008800",
                  fontSize: 12,
                  fontWeight: "bold",
                  marginBottom: 4,
                }}
              >
                TITLE
              </div>
              <textarea
                style={{
                  borderRadius: 0,
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  fontSize: 16,
                  padding: "8px 4px",
                  height: "auto",
                }}
                rows={2}
                onChange={(event) =>
                  this.setState({ newSectionTitle: event.target.value })
                }
                value={newSectionTitle}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 8,
              }}
            >
              <div
                style={{
                  color: "#008800",
                  fontSize: 12,
                  fontWeight: "bold",
                  marginBottom: 4,
                }}
              >
                POSITION
              </div>
              <select
                style={{
                  display: "flex",
                  flex: 1,
                  fontSize: 16,
                }}
                value={newSectionPosition}
                onChange={(event) =>
                  this.setState({ newSectionPosition: event.target.value })
                }
              >
                <>
                  {menu.map((category, index) => (
                    <option key={index} value={index}>
                      {index + 1}
                    </option>
                  ))}
                  <option value={menu.length}>{menu.length + 1}</option>
                </>
              </select>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 12,
              backgroundColor: "#008800",
              color: "#fff",
              fontWeight: "bold",
              fontSize: 14,
              marginTop: 12,
              borderRadius: 4,
              cursor: "pointer",
              opacity: isLoading ? 0.5 : 1,
            }}
            onClick={async () => {
              if (isLoading) {
                return;
              }

              const title = this.state.newSectionTitle.trim();

              if (!title) {
                return alert("Please provide a title for this section.");
              }

              let image;

              if (newSectionImageFile) {
                try {
                  image = await AnitaAPI.Merchant.uploadPhoto(
                    this.state.premises.id,
                    newSectionImageFile
                  );

                  if (!image) {
                    this.setState({ isLoading: false });
                    return alert("Upload failed.");
                  } else {
                    console.log("uploaded image", image);
                  }
                } catch (error) {
                  this.setState({ isLoading: false });
                  console.log("error uploading image", error);
                  return alert(`Upload failed. ${error.message}`);
                }
              }

              const section = { title, data: [], image };
              const menu = [...this.state.menu];
              menu.splice(this.state.newSectionPosition, 0, section);

              this.setState({ isLoading: true });

              try {
                await this.updateMenu(menu);
              } catch (error) {
                if (error instanceof MenuSyncError) {
                  return this.setState({ isOutOfSync: true });
                }

                alert(`Unable to save section. ${error.message}`);

                this.setState({
                  isLoading: false,
                });

                return;
              }

              this.setState({
                isAddingSection: false,
                menu,
                selectedSection: section,
                isLoading: false,
              });
            }}
          >
            {isLoading ? "PLEASE WAIT" : "SAVE SECTION"}
          </div>
        </Modal>
        <Modal
          isOpen={viewingImage != null}
          onRequestClose={() => this.setState({ viewingImage: null })}
          style={{
            content: {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              top: 16,
              left: 16,
              bottom: 16,
              right: 16,
              overflow: "hidden",
            },
          }}
        >
          <img
            src={viewingImage}
            alt=""
            style={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
            }}
            onClick={() => this.setState({ viewingImage: null })}
          />
        </Modal>
        <Modal
          isOpen={isOutOfSync}
          style={{
            content: {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              top: 16,
              left: 16,
              bottom: 16,
              right: 16,
            },
          }}
        >
          <h2 style={{ textAlign: "center" }}>Out of sync</h2>
          <div style={{ textAlign: "center" }}>
            {isLoading
              ? "Your changes have not been saved because this device is out of sync and using an outdated version of your menu."
              : "This device is out of sync and using an outdated version of your menu."}
            <br />
            <br />
            Please refresh the page to fix this issue.
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 12,
              backgroundColor: "#008800",
              color: "#fff",
              fontWeight: "bold",
              fontSize: 14,
              marginTop: 32,
              borderRadius: 4,
              cursor: "pointer",
            }}
            onClick={() => window.location.reload()}
          >
            REFRESH PAGE
          </div>
        </Modal>
      </>
    );
  }

  onSearch = (searchText) => {
    const query = searchText.trim().toLowerCase();
    const searchResults = [];

    if (query.length > 1) {
      for (const section of this.state.menu) {
        for (const product of section.data) {
          if (product.name.toLowerCase().indexOf(query) >= 0) {
            searchResults.push({ section, product });
          }
        }
      }

      searchResults.sort((a, b) => {
        const indexA = a.product.name.toLowerCase().indexOf(query);
        const indexB = b.product.name.toLowerCase().indexOf(query);

        return indexA - indexB;
      });
    }

    this.setState({ searchText, searchResults, selectedSection: null }, () => {
      if (!searchText) {
        this.listRef.current.scrollTop = 0;
      }
    });
  };

  updateMenu = async (menu) => {
    const hash = await AnitaAPI.Merchant.updateMenu(
      this.state.premises.id,
      menu,
      this.state.currentHash
    );
    this.setState({ currentHash: hash });
  };

  checkIfLatestMenu = async () => {
    if (this.state.isOutOfSync || this.state.isLoading) {
      return;
    }

    try {
      const isLatest = await AnitaAPI.Merchant.isLatestMenu(
        this.state.premises.id,
        this.state.currentHash
      );

      if (!this.state.isOutOfSync && !isLatest) {
        this.setState({ isOutOfSync: true });
      }
    } catch (error) {
      console.log("error checking menu", error);
    }
  };
}

class ProductEditor extends React.Component {
  render() {
    const { product, onProductSelected, onImageClicked } = this.props;

    return (
      <div key={product.id} className="product-editor">
        <img
          src={product.image ? `${HOST}/static/images/${product.image}` : ``}
          // alt={product.name}
          alt=""
          style={{
            width: 48,
            height: 48,
            objectFit: "contain",
            marginRight: 8,
            cursor: "pointer",
          }}
          onClick={() =>
            onImageClicked(`${HOST}/static/images/${product.image}`)
          }
        />
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            marginRight: 8,
            textAlign: "left",
            fontSize: 14,
          }}
        >
          <div>{product.name}</div>
          {product.isSpecial && (
            <div
              style={{
                fontWeight: "bold",
                fontSize: 12,
                color: "#036bfc",
                marginTop: 4,
              }}
            >
              SPECIAL OFFER
            </div>
          )}
          {(product.outOfStock ||
            (product.outOfStockUntil &&
              product.outOfStockUntil > Date.now())) && (
            <div style={{ fontWeight: "bold", fontSize: 12, color: "#eb4034" }}>
              OUT OF STOCK
            </div>
          )}
        </div>
        <div style={{ fontWeight: "bold", fontSize: 14 }}>
          £{(product.prices[0] / 100.0).toFixed(2)}
        </div>
        <i
          className="material-icons"
          style={{
            backgroundColor: "#fca503",
            padding: 12,
            borderRadius: 32,
            color: "#fff",
            marginLeft: 16,
            cursor: "pointer",
          }}
          onClick={() => onProductSelected(product)}
        >
          edit
        </i>
      </div>
    );
  }
}
