import React from 'react'
import Styled from 'styled-components'

import AnitaAPI from '../lib/AnitaAPI'
import AppContext from '../contexts/app'

import {
  Widget,
  WidgetContainer,
  Spacer,
  Label,
  Input,
  ErrorMessage,
  Button
} from '../components/Form'

export default class AddPremises extends React.Component {
  static contextType = AppContext

  state = {
    name: '',
    streetAddress: '',
    city: '',
    postcode: '',
    phoneNumber: '',
    errors: {}
  }

  async componentDidMount() {
    if (this.context.account == null) {
      this.props.history.replace('/log-in')
      return
    }
  }

  render() {
    return (
      <Container>
        <h1>Add new premises</h1>
        <Spacer />
        <PremisesForm onSubmit={this.onSubmit}>
          <WidgetContainer>
            <Widget>
              <Label>Name</Label>
              <Input
                type='text'
                name='name'
                value={this.state.name}
                onChange={this.onInputChange}
              />
              <ErrorMessage>{this.state.errors.name}</ErrorMessage>
            </Widget>
            <Spacer />
            <Widget>
              <Label>Street address</Label>
              <Input
                type='text'
                name='streetAddress'
                value={this.state.streetAddress}
                onChange={this.onInputChange}
              />
              <ErrorMessage>{this.state.errors.streetAddress}</ErrorMessage>
            </Widget>
            <Spacer />
            <Widget>
              <Label>City</Label>
              <Input
                type='text'
                name='city'
                value={this.state.city}
                onChange={this.onInputChange}
              />
              <ErrorMessage>{this.state.errors.city}</ErrorMessage>
            </Widget>
            <Spacer />
            <Widget>
              <Label>Postcode</Label>
              <Input
                type='text'
                name='postcode'
                value={this.state.postcode}
                onChange={this.onInputChange}
              />
              <ErrorMessage>{this.state.errors.postcode}</ErrorMessage>
            </Widget>
            <Spacer />
            <Widget>
              <Label>Phone number</Label>
              <Input
                type='text'
                name='phoneNumber'
                value={this.state.phoneNumber}
                onChange={this.onInputChange}
              />
              <ErrorMessage>{this.state.errors.phoneNumber}</ErrorMessage>
            </Widget>
            <Spacer />
            <Button>Submit</Button>
          </WidgetContainer>
        </PremisesForm>
      </Container>
    )
  }

  onSubmit = async event => {
    event.preventDefault()

    const { name, streetAddress, city, postcode, phoneNumber } = this.state

    try {
      await AnitaAPI.Merchant.addPremises(
        name, streetAddress, city, postcode, phoneNumber
      )
    } catch (err) {
      if (err.parameters != null) {
        this.setState({ errors: { ...err.parameters } })
      } else {
        console.log('unknown error', err)
      }

      return
    }

    this.props.history.replace('/premises')
  }

  onInputChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }
}

const Container = Styled.div`
  padding: 16px;
`

const PremisesForm = Styled.form`
  max-width: 512px;
  margin: auto;
  padding: 0 16px 0 16px;
`