import React from 'react'
import Beautify from 'json-beautify'

export default class MenuEditor extends React.Component {
  state = {
    menu: null,
    jsonEditorText: '',
    searchBarText: '',
    selectedCategory: null,
    selectedProduct: null,
    searchResults: null,
    isAddingNewProduct: false,
    latestId: 0
  }

  render() {
    const { 
      jsonEditorText,
      searchBarText,
      menu,
      selectedCategory,
      selectedProduct,
      isAddingNewProduct,
      latestId,
      searchResults
    } = this.state

    return (
      <div 
        style={{ 
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          margin: 16,
          border: '1px solid #eee',
        }}
      >
        <JSONEditor 
          text={jsonEditorText}
          onTextChanged={text => this.setState({ jsonEditorText: text })}
          onLoadClicked={this.loadMenuFromJSON}
          onUpdateClicked={this.updateJSONFromMenu}
          onClearClicked={() => this.setState({ jsonEditorText: '' })}
        />
        {menu != null &&
          <>
            <SearchBar 
              text={searchBarText}
              onTextChanged={this.onSearchTextChanged}
            />
            <div
              style={{
                padding: 16,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                borderBottom: '1px solid #eee'
              }}
            >
              <Button
                label='Clear search'
                onClick={() => this.setState({ searchResults: null, selectedProduct: null, searchBarText: '' })}
              />
              <Button
                label='Add new category'
              />
              <Button
                label='Add new product'
                onClick={() => this.setState({ isAddingNewProduct: true, selectedProduct: null })}
              />
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              {searchResults == null &&
                <CategoryList 
                  categories={menu}
                  selectedCategory={selectedCategory}
                  onCategorySelected={this.onCategorySelected}
                  onCategoryMoved={this.onCategoryMoved}
                />
              }
              <ProductList 
                products={searchResults != null
                  ? searchResults
                  : selectedCategory != null
                    ? selectedCategory.data
                    : null
                }
                selectedProduct={selectedProduct}
                onProductSelected={this.onProductSelected}
                onProductUpdated={this.onProductUpdated}
                isAddingNewProduct={isAddingNewProduct}
                latestProductId={latestId}
              />
            </div>
          </>
        }
      </div>
    )
  }

  loadMenuFromJSON = () => {
    const { jsonEditorText } = this.state
    let menu

    try {
      menu = JSON.parse(jsonEditorText)
    } catch (error) {
      console.log(error)
      return alert(error.message)
    }

    const usedIds = []
    let latestId = 0

    for (const category of menu) {
      for (const product of category.data) {
        if (usedIds[product.id]) {
          console.log(`DUPLICATE PRODUCT ID: ${product.id}`)
        }

        usedIds[product.id] = true

        if (product.id > latestId) {
          latestId = product.id
        }
      }
    }

    console.log(`Latest ID: ${latestId}`)

    for (const category of menu) {
      category.data.sort((a, b) => a.name.localeCompare(b.name))
    }

    const totalProducts = menu.reduce(
      (total, category) => total + category.data.length,
      0
    )

    console.log("totalProducts", totalProducts)
    
    this.setState({ 
      menu,
      selectedCategory: menu[0] || null, 
      selectedProduct: null,
      searchBarText: '',
      searchResults: null,
      latestId
    })
  }

  updateJSONFromMenu = () => {
    console.log('updateJSONFromMenu')
    this.setState({ jsonEditorText: Beautify(this.state.menu, null, 2) })
  }

  onSearchTextChanged = text => {
    console.log('onSearchTextChanged', text)
    this.setState({ searchBarText: text })

    if (text.length < 3) {
      this.setState({ searchResults: null, selectedProduct: null })
      return
    }
    
    const searchText = text.toLowerCase()
    const searchResults = []

    for (const category of this.state.menu) {
      for (const product of category.data) {
        const id = product.id.toString()
        const name = product.name.toLowerCase()
        const image = product.image ? product.image.toLowerCase() : ''

        if (
          id.includes(searchText) ||
          name.includes(searchText) ||
          image.includes(searchText)) {
          searchResults.push(product)
        }
      }
    }

    console.log('Search results', searchResults)
    this.setState({ searchResults, selectedProduct: null })
  }

  onCategorySelected = category => {
    this.setState({ 
      selectedCategory: category,
      selectedProduct: null,
      isAddingNewProduct: false
    })
  }

  onCategoryMoved = (category, direction) => {
    console.log('onCategoryMoved', category, direction)

    const { menu } = this.state

    const originalIndex = menu.indexOf(category)

    if (originalIndex < 0) {
      console.log('category to move not found')
      return
    }

    let newIndex = (originalIndex + direction) % menu.length

    if (newIndex < 0) {
      newIndex = menu.length - 1
    }

    console.log(`moving from index ${originalIndex} to index ${newIndex}`)

    menu[originalIndex] = menu[newIndex]
    menu[newIndex] = category

    this.setState({ menu })
  }

  onProductSelected = product => {
    this.setState({
      selectedProduct: product,
      isAddingNewProduct: false
    })
  }

  onProductUpdated = product => {
    console.log('Saving product', product)

    const menu = [...this.state.menu]

    for (const category of menu) {
      for (let i = 0; i < category.data.length; i++) {
        const existingProduct = category.data[i]

        if (existingProduct.id === product.id) {
          category.data[i] = product
          this.setState({ menu, selectedProduct: product })
          console.log('Updated existing product')

          if (this.state.searchBarText) {
            this.onSearchTextChanged(this.state.searchBarText)
          }

          return
        }
      }
    }

    for (let i = 0; i < menu.length; i++) {
      const category = menu[i]

      if (category === this.state.selectedCategory) {
        menu[i] = {
          ...menu[i],
          data: [product, ...menu[i].data]
        }
        this.setState({ 
          menu, 
          selectedCategory: 
          menu[i], 
          selectedProduct: product, 
          isAddingNewProduct: false,
          latestId: product.id
        })
        console.log('Added new product')
        return
      }
    }

    console.log('Something went wrong')
  }
}

const JSONEditor = ({ 
  text,
  onTextChanged,
  onLoadClicked,
  onUpdateClicked,
  onClearClicked
}) => {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        minHeight: 200,
        padding: 16
      }}
    >
      <textarea 
        placeholder='Paste JSON here!'
        value={text}
        onChange={e => onTextChanged(e.target.value)}
        style={{
          flex: 1,
          fontFamily: 'monospace',
          fontSize: 14
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          marginLeft: 16
        }}
      >
        <Button
          label='Load'
          onClick={onLoadClicked}
        />
        <Button
          label='Update'
          onClick={onUpdateClicked}
        />
        <Button
          label='Clear'
          onClick={onClearClicked}
        />
      </div>
    </div>
  )
}

const Button = ({ label, onClick }) => {
  return (
    <div
      style={{
        backgroundColor: '#008800',
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 12,
        padding: 16,
        textTransform: 'uppercase',
        cursor: 'pointer'
      }}
      onClick={onClick}
    >
      {label}
    </div>
  )
}

const SearchBar = ({ text, onTextChanged }) => {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        padding: 16,
        borderTop: '1px solid #eee',
        borderBottom: '1px solid #eee'
      }}
    >
      <input
        placeholder='Search for products...'
        style={{
          flex: 1,
          padding: 8,
          border: '1px solid #eee',
          fontSize: 16
        }}
        type='text'
        value={text}
        onChange={e => onTextChanged(e.target.value)}
      />
    </div>
  )
}

const CategoryList = ({ categories, selectedCategory, onCategorySelected, onCategoryMoved }) => {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid #eee'
      }}
    >
      {categories.map(category => (
        <Category 
          key={category.title}
          category={category} 
          isSelected={category === selectedCategory}
          onClick={() => onCategorySelected(category)}
          onMove={onCategoryMoved}
        />
      ))}
    </div>
  )
}

const Category = ({ category, isSelected, onClick, onMove }) => {
  const { title } = category

  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 16,
        borderBottom: '1px solid #eee',
        fontWeight: isSelected 
          ? 'bold' 
          : 'normal',
        backgroundColor: isSelected
          ? '#008800'
          : '#fff',
        color: isSelected
          ? '#fff'
          : '#000',
        cursor: 'pointer'
      }}
    >
      <div>
        {title} - {category.data.length}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <div 
          style={{ padding: 8, border: '1px solid #eee' }}
          onClick={() => onMove(category, -1)}
        >
          <span role='img' aria-label='up'>☝️</span>
        </div>
        <div style={{ width: 16 }}></div>
        <div 
          style={{ padding: 8, border: '1px solid #eee' }}
          onClick={() => onMove(category, 1)}
        >
          <span role='img' aria-label='down'>👇</span>
        </div>
      </div>
    </div>
  )
}

const ProductList = ({ products, selectedProduct, onProductSelected, onProductUpdated, isAddingNewProduct, latestProductId }) => {
  return (
    <div
      style={{
        flex: 2,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <>
        {isAddingNewProduct &&
          <div style={{ border: '2px solid #008800' }}>
            <h3>New product</h3>
            <ProductEditor
              product={{
                id: latestProductId + 1,
                name: '',
                image: null,
                prices: [0, 0]
              }}
              onProductUpdated={onProductUpdated}
            />
          </div>
        }
        {products.map(product => {
          const isSelected = product === selectedProduct

          return (
            <div 
              key={product.name}
              style={{
                border: isSelected
                  ? '2px solid #008800'
                  : 'none'
              }}
            >
              <Product
                product={product}
                isSelected={isSelected}
                onClick={() => onProductSelected(product)}
              />
              {isSelected &&
                <ProductEditor 
                  product={product}
                  onProductUpdated={onProductUpdated}
                />
              }
            </div>
          )
        })}
      </>
    </div>
  )
}

const Product = ({ product, isSelected, onClick }) => {
  const { id, image, name, prices } = product

  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 16,
        borderBottom: '1px solid #eee',
        cursor: 'pointer',
        // backgroundColor: isSelected
        //   ? '#008800'
        //   : '#fff',
        // color: isSelected
        //   ? '#fff'
        //   : '#000'
      }}
    >
      <img
        style={{
          width: 64,
          height: 64,
          borderWidth: 1,
          borderColor: '#008800',
          fontSize: 12,
          wordWrap: 'break-word',
          fontFamily: 'monospace',
          objectFit: 'contain'
        }}
        src={image == null ? '' : `https://api.anita-delivery.com/static/images/${image}`}
        alt=''
      />
      <div
        style={{ 
          flex: 1, 
          textAlign: 'left',
          marginLeft: 16,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div style={{ fontWeight: 'bold' }}>{name}</div>
        <div 
          style={{ 
            marginTop: 8, 
            fontSize: 14,
            fontFamily: 'monospace' 
          }}>
            {id} - {image || 'NULL'}
        </div>
      </div>
      <div>
        £{(prices[0] / 100).toFixed(2)}
      </div>
    </div>
  )
}

const ProductEditor = ({ product, onProductUpdated }) => {
  const [nameText, setNameText] = React.useState(product.name)
  const [imageText, setImageText] = React.useState(product.image || '')
  const [priceValue, setPriceValue] = React.useState(product.prices[0] || 0)

  return (
    <div
      style={{
        padding: 16,
        borderBottom: '1px solid #eee',
        // backgroundColor: '#008800',
        // color: '#fff',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 4
        }}
      >
        <div style={{ fontWeight: 'bold', marginRight: 8, flex: 1 }}>ID</div>
        <div style={{ flex: 4, textAlign: 'left' }}>{product.id}</div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 4
        }}
      >
        <div style={{ fontWeight: 'bold', marginRight: 8, flex: 1 }}>Name</div>
        <input
          placeholder='Product name'
          value={nameText}
          onChange={e => setNameText(e.target.value)}
          style={{
            padding: 8,
            fontSize: 16,
            flex: 4
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 4
        }}
      >
        <div style={{ fontWeight: 'bold', marginRight: 8, flex: 1 }}>Image</div>
        <input
          placeholder='Product image'
          value={imageText}
          onChange={e => setImageText(e.target.value)}
          style={{
            padding: 8,
            fontSize: 16,
            flex: 4
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 4
        }}
      >
        <div style={{ fontWeight: 'bold', marginRight: 8, flex: 1 }}>Price</div>
        <input
          placeholder='Product price'
          type='number'
          value={priceValue}
          onChange={e => setPriceValue(e.target.value)}
          style={{
            padding: 8,
            fontSize: 16,
            flex: 4
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          marginTop: 8
        }}
      >
        <Button
          label='Save product'
          onClick={() => onProductUpdated({
            ...product,
            name: nameText,
            image: imageText.length > 0
              ? imageText
              : null,
            prices: [parseInt(priceValue), parseInt(priceValue)]
          })}
        />
        <Button
          label='Cancel'
          onClick={() => {
            setNameText(product.name)
            setImageText(product.image || '')
            setPriceValue(product.prices[0] || 0)
          }}
        />
        <Button
          label='Delete'
        />
      </div>
    </div>
  )
}