import React from 'react'
import Styled from 'styled-components'

import AnitaAPI from '../lib/AnitaAPI'
import AppContext from '../contexts/app'

import {
  Widget,
  WidgetContainer,
  Spacer,
  Label,
  Input,
  ErrorMessage,
  Button
} from '../components/Form'

export default class ForgotPassword extends React.Component {
  static contextType = AppContext

  state = {
    email: '',
    errors: {}
  }

  render() {
    return (
      <Container>
        <h1>Reset your password</h1>
        <p>Forgot your password? Enter your email address and we'll send you a link to reset your password.</p>
        <Spacer />
        <ForgotPasswordForm onSubmit={this.onSubmit}>
          <WidgetContainer>
            <Widget>
              <Label>Email address</Label>
              <Input
                type='email'
                name='email'
                value={this.state.email}
                onChange={this.onInputChange}
              />
              <ErrorMessage>{this.state.errors.email}</ErrorMessage>
            </Widget>
            <Spacer />
            <Button>Reset password</Button>
          </WidgetContainer>
        </ForgotPasswordForm>
        <Spacer />
      </Container>
    )
  }

  onSubmit = async event => {
    event.preventDefault()

    const { email } = this.state

    try {
      await AnitaAPI.Account.requestPasswordReset(email)
      alert("You'll receive an email from us shortly if there's an account registered to that email address.")
    } catch (err) {
      if (err.parameters != null) {
        this.setState({ errors: { ...err.parameters } })
      } else {
        console.log('unknown error', err)
      }

      return
    }
  }

  onInputChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }
}

const Container = Styled.div`
  padding: 16px;
`

const ForgotPasswordForm = Styled.form`
  max-width: 512px;
  margin: auto;
  padding: 0 16px 0 16px;
`